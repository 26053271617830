<template>
  <div class="vp">
    <div class="vp__item" v-for="(item,index) in vp" v-bind:key="_uid+index">
      <b-row>
        <b-col lg="4" xl="6">
          <div class="vp__right">
            <img :src="item.vp_image" />
          </div>
        </b-col>
        <b-col lg="8" xl="6">
          <div class="vp__left">
            <div v-for="(content, content_index) in item.vp_content" v-bind:key="_uid+content_index">
              <div class="vp__icon">
                <vb-icon :name="content.vp_icon" :color="'FBAE48'" />
              </div>
              <div>
                <div class="h3 vp__title" v-html="content.vp_title" />
                <div class="vp__text" v-html="content.vp_text" />
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'value-proposition',
  components: {
  },
  props: {
    vp: {
      type: Array,
      default: () => { return [] }
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss">
.vp {
  background:$white;
  border-radius: $border-radius;
  overflow: hidden;

  &__item {}

  a {
    color: $vb-body-color;
    &:hover {
      //color: $link-color;
      text-decoration: underline;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    & > div {
      display: flex;
      padding: $grid-gutter-width*2 $grid-gutter-width*2 $grid-gutter-width*2 $grid-gutter-width;

      &:not(:last-child) {
        margin-bottom:$grid-gutter-width*2;
      }

      @include media-breakpoint-up(lg) {
        padding: 0 $grid-gutter-width*2 0 0;
      }
    }
  }

  &__right {
    position:relative;
    min-height: 240px;
    img {
      position:absolute;
      height:100%;
      width:100%;
      object-fit:cover;
      object-position: top center;
    }
    @include media-breakpoint-up(md) {
      min-height: 500px;
    }
  }

  &__icon {
    margin:0 1rem;
  }

  &__title {
    @include font-primary-semibold;
    margin-bottom: 1rem;
  }

  &__text {
    //font-size: $font-size-sm;
  }
}
</style>